
/* Normal desktop :1366px. */
@media (min-width: 1170px) and (max-width: 1440px) {
  /* DoctorAppointment */
  .card-annoucement .card-body {
    padding: 50px 0px;
  }
  .nationality {
    margin-right: 0;
    width: 5rem;
  }
  /* Edit Profile */
  .form-group {
    margin-bottom: 0;
    padding: 0px;
  }
  /* NewAppintment choose date */
  .DayPicker {
    margin-left: -2em;
  }
  .form-group {
    margin-bottom: 0;
    padding: 10px;
  }
  .login-image {
    height: 100%;
  }

}

@media (min-width: 1440px) {
  /* DoctorAppointment */
  .card-annoucement .card-body {
    padding: 50px 0px;
  }
  .nationality {
    margin-right: 0;
    width: 5rem;
  }

  /* Edit Profile */
  .speciality {
    flex: 0 0 40%;
    max-width: 58%;
  }
  .career {
    flex: 0 0 60%;
    max-width: 60%;
  }

  /* Home */
  .calendar {
    height: calc(100% - -460px);
  }
  .valorations {
    margin-top: -2rem;
  }
  .row {
    overflow: initial;
  }

  .row-block {
      display: block;
  }
  /* NewAppintment choose date */
  .DayPicker {
    margin-left: -1.4em;
  }
  /* DetailedEMD */
  .patient-info {
    flex: 0 0 50%;
    max-width: 25%;
  }
}

@media (max-width: 1024px) {
  .login-image {
    height: 100%;
  }
  /* DoctorAppointment */
  .card-annoucement .card-body {
    padding: 50px 0px;
  }
  .nationality {
    margin-right: 0;
    width: 9rem;
  }
  .user-appointment {
    -ms-flex: 0 0 25%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 40.666667%;
  }

  /* Home */
  .calendar {
    height: calc(100% - -410px);
  }
  .valorations {
    margin-top: -2rem;
  }
  /* NewAppintment choose date */
  .DayPicker {
    margin-left: -1.4em;
  }

  /* DetailedEMD */
  .patient-info {
    flex: 0 0 50%;
    max-width: 25%;
  }

  /* Edit Profile */
  .speciality {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .career {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .consultation-list {
    width: 45rem;
    margin-left: -1rem;
  }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {
}

/* Iphone 5s y huawei */
@media only screen and (max-device-width: 480px) {
  /* Home */
  .calendar {
    height: calc(100% - -250px);
  }
  .valorations {
    margin-top: 16rem;
  }
  /* NewAppoinment choose date */
  .nationaity {
    width: 6rem;
  }
  .crossfade {
    display: none;
  }
  /* NewAppintment choose date */
  .DayPicker {
    margin-left: -1.4em;
  }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .card-round {
    width: 18rem;
  }

  .col-md-3 {
    max-width: 44%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-left: 25px;
  }

  /* Edit profile */
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  /* Home */
  .calendar {
    height: calc(100% - -250px);
  }
  .valorations {
    margin-top: 16rem;
  }
  /* NewAppoinment choose date */
  .nationaity {
    width: 6rem;
  }
  .DayPicker {
    margin-left: -1.4em;
  }

  .consult-info {
    flex: 0 0 100%;
    max-width: 50%;
    margin: 0 auto;
  }
  .login-image {
    height: 100%;
  }
}

/* small mobile :320px. */
@media (max-width: 767px) {
  /* Home */
  .calendar {
    height: calc(100% - -250px);
  }
  .valorations {
    margin-top: 16rem;
  }
  /* NewAppoinment choose date */
  .nationality {
    width: 18rem;
    margin-left: -6rem;
  }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 375px) and (max-width: 767px) {
  .calendar {
    height: calc(100% - -250px);
  }
  .valorations {
    margin-top: 16rem;
  }

  .appointment-module {
    width: 23.4rem;
  }
  /* .appointment{
        width:3rem;
    } */

  .row {
    overflow: initial;
  }
  .DayPicker {
    margin-left: -1.4em;
  }

  .login .show-password {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }
  .login-image {
    display: none;
  }
}

@media (min-width: 768px) {
  .consult-info {
    flex: 0 0 100%;
    max-width: 50%;
    margin: 0 auto;
  }
}

/* Monitores */

@media only screen and (min-device-width: 1280px) {
  

}